@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {
}
//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  .grecaptcha-badge{
    display: none;
  }
}
//Mobile End--------------------------------------------------------------------------------
