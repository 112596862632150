  //-------------------------------------------------------
  //vivarobet.am Start-------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //Config Start-------------------------------------------
  //-------------------------------------------------------
  $isDesktop: false;
  $isMobile: true;
  //-------------------------------------------------------
  $hasSportsBook: true;
  $hasCasino: true;
  //-------------------------------------------------------
  $default-skin-color: #020203;
  $default-text-color: #000000;
  //-------------------------------------------------------
  $hero-color: #A61F67;
  //$brand-color-1: #006E66;
  $brand-color-1: #008d47;
  $brand-color-2: #FFCA3A;
  //-------------------------------------------------------
  $status-color-success: #079264;
  $status-color-fail: #B32336;
  //-------------------------------------------------------
  //Config End---------------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //vivarobet.am End---------------------------------------
  //-------------------------------------------------------
