@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {
  //-------------------------------------------------------
  //Layout Start-------------------------------------------
  //-------------------------------------------------------
  .layout-bc {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: 'content-holder smart-panel-holder';
    grid-template-columns: 1fr auto;
    grid-gap: 0;
  }
  .lt-content-holder-bc {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: 'header-holder' 'common-content-holder' 'footer-holder';
    grid-area: content-holder;
    grid-template-rows: auto 1fr auto;
    grid-gap: 0;
    -ms-grid-column: 1;
    &.home-page-fixed-sides {}
    &.sp-dynamic-content-opened {
      > .lt-header-holder-bc, > .lt-footer-holder-bc {
        filter: blur(10px);
      }
      .lt-dynamic-content-holder {
        > div {
          > div {
            > div {
              filter: blur(10px);
            }
          }
        }
      }
    }
  }
  @keyframes blur-overlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  .lt-smart-panel-holder-bc {
    width: $desktop-smart-panel-width;
    height: 100%;
    position: relative;
    z-index: 30;
    grid-area: smart-panel-holder;
    -ms-grid-column: 2;
  }
  .lt-smart-panel-backface-layer-bc {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    background: rgba($hero-color, 0.2);
    background: var(--hero-color-rgba-20);
    [dir=ltr] & {
      left: 0;
      padding-right: $desktop-smart-panel-width + $desktop-dynamic-panel-width;
    }
    [dir=rtl] & {
      right: 0;
      padding-left: $desktop-smart-panel-width + $desktop-dynamic-panel-width;
    }
  }
  .lt-smart-panel-backface-bc {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .lt-header-holder-bc {
    width: 100%;
    grid-area: header-holder;
    position: fixed;
    top: 0;
    z-index: 2;
    -ms-grid-row: 1;
    [dir=ltr] & {
      left: 0;
      padding-right: $desktop-smart-panel-width;
    }
    [dir=rtl] & {
      right: 0;
      padding-left: $desktop-smart-panel-width;
    }
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }
  .lt-common-content-holder-bc {
    display: grid;
    grid-area: common-content-holder;
    grid-template-areas: 'dynamic-content-holder betslip-holder';
    grid-template-columns: 1fr auto;
    grid-gap: 0;
    -ms-grid-row: 2;
    position: relative;
    z-index: 1;
    padding-top: $desktop-header-height;
  }
  .lt-dynamic-content-holder {
    grid-area: dynamic-content-holder;
    -ms-grid-column: 1;
  }
  .lt-betslip-holder {
    grid-area: betslip-holder;
    -ms-grid-column: 2;
  }
  .lt-footer-holder-bc {
    grid-area: footer-holder;
    -ms-grid-row: 3;
    position: relative;
    padding-top: 24px;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .layout-bc {
      height: initial;
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
    }
    .lt-content-holder-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: row;
      -ms-grid-template-columns: auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 100%;
      -ms-grid-rows: auto 1fr auto;
    }
    .lt-common-content-holder-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
      padding-top: $desktop-header-height;
    }
  }
  @media screen and (min-width: $desktop-breakpoint-1) {
    .lt-content-holder-bc {
      &.home-page-fixed-sides {

      }
    }
  }
  @media screen and (max-width: $desktop-breakpoint-1 - 1px) {
    .lt-content-holder-bc {
      &.home-page-fixed-sides {
      }
    }
  }
  //-------------------------------------------------------
  //Layout End---------------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //Entrance Form Start------------------------------------
  //-------------------------------------------------------
  .entrance-form-bc {
    //Popup Start------------------------------------------
    &.popup {
      &.login {
        margin-top: 30px;
      }
      &.registration {}
      .entrance-f-item-bc {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 10px;
        }
        &.reg-ext-1 {
          margin-top: 16px;
        }
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 16px;
      }*/
    }
    //Popup End--------------------------------------------

    //Panel Start------------------------------------------
    &.panel {
      &.login {}
      &.registration {}
      .entrance-form-action-item-bc,
      .entrance-form-action-bc {
        width: 100%;
      }
      .entrance-f-item-bc {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 8px;
        }
        &.reg-ext-1 {
          margin-top: 12px;
        }
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 12px;
      }*/
    }
    //Panel End--------------------------------------------
  }
  .entrance-form-content-bc {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    &.single-side {}
    &.double-sides {
      > .entrance-form-content-section-bc {
        [dir=ltr] & {
          border-right: 48px solid transparent;
        }
        [dir=rtl] & {
          border-left: 48px solid transparent;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
  .entrance-form-content-section-bc {
    display: table-cell;
    vertical-align: top;
  }
  .entrance-f-item-bc {
    &.reg-ext-1 {
      height: 62px;
      position: relative;

      > .input-control-holder-bc {
        &.recaptcha {
          position: absolute;
          top: 0;
          transform-origin: top center;

          [dir=ltr] & {
            left: 50%;
            transform: translateX(-50%) scale(0.8);
          }
          [dir=rtl] & {
            right: 50%;
            transform: translateX(50%) scale(0.8);
          }
        }
      }
    }
  }
  .entrance-form-actions-holder-bc {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    &.login-ext-1 {

    }
    &.reg-ext-1 {

    }
  }
  .entrance-form-action-item-bc {
    display: inline-block;
    vertical-align: top;

    &.left {
      [dir=ltr] & {
        float: left;
      }
      [dir=rtl] & {
        float: right;
      }
    }

    &.right {
      [dir=ltr] & {
        float: right;
      }
      [dir=rtl] & {
        float: left;
      }
    }
  }
  .entrance-form-action-bc {
    display: inline-block;
    vertical-align: top;
    min-width: 102px;
    height: 30px;
    padding: 0 8px;
    border: none;
    border-radius: $desktop-border-radius-size-1;
    outline: none;
    background-color: $hero-color;
    font-family: inherit;
    font-size: 12px;
    text-transform: uppercase;
    color: set-color($hero-color);
    transition-property: background-color, color;
    transition-duration: $default-transition-duration;
    cursor: pointer;
    &:hover {
      background-color: $hero-color-extended;
      color: set-color($hero-color-extended);
    }
  }
  /*.entrance-form-forgot-password-holder-bc {
    text-align: center;
    line-height: 0;
  }*/
  /*.entrance-f-i-forgot-password-bc {
    display: inline-block;
    vertical-align: top;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 12px;
    color: $default-text-palette-13;
    color: var(--text-palette-13);
    line-height: 14px;
    transition: color $default-transition-duration ease;
    cursor: pointer;
    &:hover {
      color: $hero-color;
      color: var(--hero-color);
    }
  }*/
  .entrance-form-error-message-holder-bc {
    margin-bottom: 6px;
  }
  .entrance-f-error-message-bc {
    display: block;
    font-weight: normal;
    font-size: 10px;
    color: $status-color-fail;
    color: var(--status-color-fail);
    line-height: 12px;
  }
  //-------------------------------------------------------
  //Entrance Form End--------------------------------------
  //-------------------------------------------------------
}

//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  .layout-bc {
    width: 100%;
    display: grid;
    grid-template-areas: "header" "content" "footer" "betslip" "navigation";
    grid-template-rows: auto 9fr auto auto auto;
    grid-template-columns: 100%;
    min-height: 100vh;
    &.navigation-is-visible {
      .layout-header-holder-bc {}
    }
    &.smart-panel-is-visible {
      .layout-header-holder-bc {}
      &.navigation-is-visible {
        .layout-header-holder-bc {}
      }
    }
    &.is-home-page {}
    &.is-prematch-page {}
    &.is-live-page {}
    &.betslipt-is-opened {}
    &.betslip-is-compact{
      height: $mobile-betslip-compact-height;
    }
    &.betslip-is-first-bet {}
    &.betslip-is-full {}
    > .bc-loader {
      position: fixed;
    }
  }
  .layout-header-holder-bc {
    grid-area: header;
    height: $mobile-header-main-section-height + $mobile-smart-panel-height;
    //transition: height $default-transition-duration ease;
  }
  .layout-content-holder-bc {
    grid-area: content;
    width: 100%;
  }
  .layout-footer-holder-bc {
    grid-area: footer;
    transform: translate3d(0,0,0);
  }
  .layout-betslip-holder-bc {
    grid-area: betslip;
  }
  .layout-navigation-holder-bc {
    grid-area: navigation;
    height: 48px;
    &:empty{
      height: 0;
    }
  }
  //------- Tab Navigation Start------
  .tab-navigation-w-bc{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    height: 49px;
    background-color: $default-skin-palette-3;
    background-color: var(--skin-palette-3);
    box-shadow: 0 0 3px $bg-color-black-rgba-10;
    display: table;
    table-layout: fixed;
    transform: translate3d(0,0,0);
  }
  .tab-nav-item-bc{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    transition: background-color $default-transition-duration;
    position: relative;
    color: $default-text-palette-8;
    color: var(--text-palette-8);
    &:active {
      background-color: $bg-color-black-rgba-10;
    }
    &.active{
      color: $default-text-palette-18;
      color: var(--text-palette-18);
    }
  }
  .tab-nav-title-bc{
    width: 100%;
    font-size: 11px;
    line-height: 14px;
  }
  .tab-nav-icon-bc{
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    line-height: 24px;
  }
  .count-v-bc{
    position: absolute;
    top: 4px;
    left: 50%;
	min-width: 12px;
    font-size: 8px;
    line-height: 12px;
    height: 12px;
    text-align: center;
    padding: 0 3px;
    border-radius: 6px;
    background: $hero-color;
    box-shadow: 0 0 0 1px $bg-color-black-rgba-10;
    color: set-color($hero-color);
		display: block;
		animation: $default-transition-duration count-blink ease-in-out;
    [dir=ltr] & {
      margin-left: 10px;
    }
    [dir=rtl] & {
      padding-right: 10px;
    }
  }
	@keyframes count-blink {
		0%{transform: scale(0);}
		100%{transform: scale(1);}
	}
  //------- Tab Navigation END--------

  ///////////////////////////////// duplicate
  .entrance-form-bc {
    //Popup Start------------------------------------------
    &.popup {
      &.login {
        margin-top: 30px;
      }
      &.registration {}
      .entrance-f-item-bc {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 10px;
        }
        &.reg-ext-1 {
          margin-top: 16px;
        }
        &:after {
          display: block;
          content: "";
          clear: both;
        }
      }
      .input-control-holder-bc.recaptcha {
        max-width: 280px;
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 16px;
      }*/
    }
    //Popup End--------------------------------------------

    //Panel Start------------------------------------------
    &.panel {
      &.login {}
      &.registration {}
      .entrance-form-action-item-bc,
      .entrance-form-action-bc {
        width: 100%;
      }
      .entrance-f-item-bc {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 8px;
        }
        &.reg-ext-1 {
          margin-top: 12px;
        }
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 12px;
      }*/
    }
    //Panel End--------------------------------------------

    .entrance-form-content-bc {
      display: table;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      &.single-side {}
      &.step-0 {
        margin-top: 30px;
      }
      &.step-1{
        ~ .entrance-form-actions-holder-bc{
          &:after{
            display: none;
          }
          &.reg-ext-1{
            margin-bottom: 14px;
            margin-top: 0;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .entrance-form-action-item-bc {}
    .entrance-form-action-bc, .hdr-user-ent-buttons-bc {
      width: 100%;
      min-width: 102px;
      height: 36px;
      padding: 0 8px;
      border: none;
      border-radius: $desktop-border-radius-size-3;
      outline: none;
      background-color: $brand-color-1;
      font-family: inherit;
      font-size: 14px;
      color: set-color($brand-color-1);
      text-transform: uppercase;
      transition-property: background-color, color;
      transition-duration: $default-transition-duration;
      cursor: pointer;
      &.trans-v{
        background: transparent;
        border: 2px solid $default-skin-palette-13;
        border: 2px solid var(--skin-palette-13);
        color: $default-text-palette-13;
        color: var(--text-palette-13);
      }
    }
    /*.entrance-form-forgot-password-holder-bc {
      text-align: center;
      line-height: 0;
    }*/
    /*.entrance-f-i-forgot-password-bc {
      display: inline-block;
      vertical-align: top;
      border: none;
      outline: none;
      background-color: transparent;
      font-family: inherit;
      font-size: 12px;
      color: $default-text-palette-13;
      color: var(--text-palette-13);
      line-height: 14px;
      transition: color $default-transition-duration ease;
      cursor: pointer;
      &:hover {
        color: $hero-color;
        color: var(--hero-color);
      }
    }*/
    .entrance-form-error-message-holder-bc {
      margin-bottom: 6px;
    }
    .entrance-f-error-message-bc {
      display: block;
      font-weight: normal;
      font-size: 10px;
      color: $status-color-fail;
      color: var(--status-color-fail);
      line-height: 12px;
    }
  }

  .steps-indicator {
    display: flex;
    margin: 20px 0 14px;
  }
  .step-indicator {
    flex: 1;
    height: 2px;
    background-color: #7d96cb;
  }
  .step-indicator-active {
    background-color: #008d47;
  }
  ////////////////////////////// duplicate END
}

//Mobile End--------------------------------------------------------------------------------



// Custom Bet on lineup header START--------------------------------------------------------
.bet-on-lineups-sign-in {
  grid-area: head;
  height: 40px;
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  background: #121524;
  border-bottom: 2px solid #A61F67;
  padding: 0 10px;
  position: sticky;
  top: 0;
  z-index: 999999;
  & + {
    .bet-on-lineups-wrapper {
      .lineup-game-field-img {
        max-width: 86vw;
        max-height: 86vh;
      }
    }
  }
}
.lineup-header-loader {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0 );
  width: 50%;
  overflow: hidden;
  > .bc-loader  {
    position: absolute;
    top: -10px;
    left: auto;
    right: 10px;
  }
}
.bet-on-lineup-logo {
  flex: 0 0 auto;
  padding-right: 6px;
  > img {
    display: block;
    max-width: 140px;
  }
}
.bet-on-lineup-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}
.bet-on-lineup-buttons-inner {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: flex-end;
  min-width: 150px;
}
.bet-on-lineup-button {
  flex: 0 0 auto;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #ffffff;
  padding: 0 10px;
  text-transform: capitalize;
  min-width: 120px;
  cursor: pointer;
  border: 1px solid #1A7051;
  height: 28px;
  transition: 180ms;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;
  &.register {
    background: #1A7051;
    &:hover {
      background: lighten(#1A7051, 14);
      border-color: lighten(#1A7051, 14);
    }
  }
  &.sign-in {
    &:hover {
      border-color: lighten(#1A7051, 14);
    }
  }
  &.sign-in-mobile,
  &.register-mobile {
    transition: none;
    border: none;
    text-transform: uppercase;
    min-width: auto;
    padding: 0;
  }
  &.register-mobile {
    color: #1A7051;
    margin-left: 10px;
  }
}
.bet-on-lineup-money {
  font-size: 14px;
  color: #b6862e;
  margin-right: 10px;
}
// Custom Bet on lineup header END--------------------------------------------------------