@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {
  //-------------------------------------------------------
  //Date Picker Start--------------------------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //Date Picker End----------------------------------------
  //-------------------------------------------------------
}

//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  .react-datepicker-wrapper {
    display: block;

    .form-control-input-bc {
      &:read-only {
        border: 2px solid $default-skin-palette-7;
        border: 2px solid var(--skin-palette-7);
      }
    }
  }
  .react-datepicker__input-container {
    display: block;
  }
}

//Mobile End--------------------------------------------------------------------------------
